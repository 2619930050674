import React from "react"
import { LoadingIcon } from "~/components/icons"

export const LoadingModalContext = React.createContext<{
	loading: boolean
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
}>({ loading: false, setLoading: () => {} })

export default function LoadingModalProvider({
	children,
}: {
	children: React.ReactNode
}) {
	const [loading, setLoading] = React.useState<boolean>(false)

	return (
		<LoadingModalContext.Provider value={{ loading, setLoading }}>
			{children}

			{loading && (
				<Portal>
					<div className="fixed top-0 left-0 w-screen h-screen bg-slate-900 bg-opacity-60">
						<div className="fixed bottom-0 left-0 right-0 z-30 w-1/2 h-64 max-w-md p-4 mx-auto text-white rounded-lg shadow-lg min-w-64 top-72 bg-slate-700 opacity-90">
							<div className="flex flex-col items-center justify-center h-full">
								<h2 className="text-xl animate-bounce">Processing...</h2>
								<LoadingIcon size={8} color="text-white" opacity="opacity-70" />
								{/* <span className="loading loading-spinner loading-lg" /> */}
							</div>
						</div>
					</div>
				</Portal>
			)}
		</LoadingModalContext.Provider>
	)
}

function Portal({ children }: { children: React.ReactNode }) {
	const portalRef = React.useRef<HTMLDivElement>(null)

	React.useEffect(() => {
		const portal = portalRef.current
		if (portal) {
			document.body.appendChild(portal)
			return () => {
				document.body.removeChild(portal)
			}
		}
	}, [])

	return (
		<div style={{ display: "none" }}>
			<div ref={portalRef}>{children}</div>
		</div>
	)
}

export function useLoadingModalContext() {
	const context = React.useContext(LoadingModalContext)

	if (!context) {
		throw new Error(
			"useLoadingModalContext must be used within a LoadingModalProvider",
		)
	}

	return context
}

export function useLoadingModal(loading: boolean) {
	const { setLoading } = useLoadingModalContext()
	React.useEffect(() => {
		if (loading) {
			setLoading(true)
		} else {
			setLoading(false)
		}
	}, [loading, setLoading])
}
